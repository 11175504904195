export { default } from '@speedwaymotors/clutch/Layout/Next/_app'

// import { TrackingProvider } from "@speedwaymotors/clutch/Contexts/TrackingContext/TrackingContext";

// function MyAppGateWay({ Component, pageProps }) {

//     return <TrackingProvider>
//         <Component pageProps={pageProps} />
//     </TrackingProvider>

// }

// export default MyAppGateWay;
